import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Navbar, Nav, Button } from 'react-bootstrap';
import logo from './pig_sharetoshi2.png';
import axios from 'axios';
import './CustomNavbar.css'; // Make sure this path is correct

import { createWeb3Modal, defaultConfig } from '@web3modal/ethers/react';




// 1. Get projectId


const projectId = "949ea41a67b07503afe6d9f6bae8276f";

// 2. Set chains
const mainnet = {
  chainId: 137,
  name: 'Polygon Mainnet',
  currency: 'MATIC',
  explorerUrl: 'https://polygonscan.com',
  rpcUrl: 'https://polygon-rpc.com'

  
}

// 3. Create a metadata object
const metadata = {
  name: 'Sharetoshi',
  description: 'Here to share',
  url: 'http://localhost:3000/', // origin must match your domain & subdomain
  icons: ['https://avatars.mywebsite.com/']
}

// 4. Create Ethers config
const ethersConfig = defaultConfig({
  /*Required*/
  metadata,

  /*Optional*/
  enableEIP6963: true, // true by default
  enableInjected: true, // true by default
  enableCoinbase: true, // true by default
  rpcUrl: 'https://polygon-rpc.com', // used for the Coinbase SDK
  defaultChainId: 137, // used for the Coinbase SDK
})

// 5. Create a Web3Modal instance
createWeb3Modal({
  ethersConfig,
  chains: [mainnet],
  projectId,
  enableAnalytics: true // Optional - defaults to your Cloud configuration
})


function NavBar() {
  const [maticPrice, setMaticPrice] = useState(0);

  useEffect(() => {
    const fetchMaticPrice = async () => {
      try {
        const response = await axios.get('https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd');
        setMaticPrice(response.data['matic-network'].usd);
      } catch (error) {
        console.error('Error fetching MATIC price:', error);
      }
    };

    fetchMaticPrice();
    const interval = setInterval(fetchMaticPrice, 60000); // Fetch price every minute

    return () => clearInterval(interval);
  }, []);


   // Function to open Twitter homepage
   const openTwitterLink = () => {
    window.open('https://twitter.com/sharetoshi', '_blank');
  };

  return (
    <Navbar expand="lg" className="custom-navbar">
      <Container fluid>
        <Navbar.Brand href="#" className="navbar-logo">
          <img src={logo} alt="Logo" className="logo" />
          <span className="logo-text">sharetoshi</span>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
         
          <Nav className="ms-auto">
            <Nav.Item className="matic-price align-self-center">
              MATIC: ${maticPrice.toFixed(2)}
            </Nav.Item>
            <Button variant="primary" className="twitter-btn" onClick={openTwitterLink}>
              Twitter/X
            </Button>
            <w3m-button />
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
